import {
    getLoomVideoEmbedUrlFromShareUrl,
    isLoomVideoUrl,
} from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.utils";
import { appendURLSearchParams } from "repoV2/utils/urls&routing/urlParams";
import React, { FC, MutableRefObject, forwardRef } from "react";
import ReactPlayer from "react-player/lazy";
import { classNames } from "repoV2/utils/common/render/classNames";
import { parseYTUrl } from "repoV2/utils/urls&routing/urls";
import { IVideoPlayer } from "./IVideoPlayer";
import styles from "./VideoPlayer.module.scss";
import { useOptimizationBasedLightProp } from "./VideoPlayer.utils";

const VideoPlayer: FC<IVideoPlayer.IProps> = forwardRef<
    ReactPlayer | HTMLDivElement,
    IVideoPlayer.IProps
>((props, ref) => {
    const {
        videoUrl = "",
        optimizeUsingThumbnail = false,
        light = false,
        className,
        enableDownload,
        loomPlayerProps,
        loomIframeWrapperClassName,
        ...extraProps
    } = props;

    const { height, width, title, onLoad, autoplay } = extraProps;
    const commonProps = {
        controls: true,
        className,
        ...extraProps,
    };
    let specificProps = {};

    const { lightProp } = useOptimizationBasedLightProp({
        mediaUrl: videoUrl as string,
        light,
        optimizeUsingThumbnail,
    });

    if (!enableDownload) {
        specificProps = {
            ...specificProps,
            config: {
                file: {
                    attributes: { controlsList: "nodownload" },
                },
            },
        };
    }

    return isLoomVideoUrl(videoUrl as string) ? (
        <div
            ref={ref as MutableRefObject<HTMLDivElement>}
            className={loomIframeWrapperClassName}
        >
            {/* TODO: fix this iframe code for loom url.
            it will currently override everything that is passed in `loomPlayerProps`
            discuss and implement */}
            <iframe
                title={title}
                src={appendURLSearchParams(
                    getLoomVideoEmbedUrlFromShareUrl(videoUrl as string),
                    { autoplay: autoplay ? 1 : undefined }
                )}
                className={classNames(styles.video, loomPlayerProps?.className)}
                style={{ height, width, ...loomPlayerProps?.style }}
                onLoad={onLoad}
                allowFullScreen
                {...loomPlayerProps}
            />
        </div>
    ) : (
        <ReactPlayer
            ref={ref as MutableRefObject<ReactPlayer>}
            url={typeof videoUrl === "string" ? parseYTUrl(videoUrl) : videoUrl}
            config={{
                file: {
                    attributes: { controlslist: "nodownload" },
                },
            }}
            light={lightProp}
            {...specificProps}
            {...commonProps}
        />
    );
});

export default VideoPlayer;
