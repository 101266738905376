import { youtube_video_url_formats } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.constants";
import { checkIsArrayEmpty } from "../common/dataTypes/array";
import { checkIsStringEmpty } from "../common/dataTypes/string";
import { deleteURLSearchParams } from "./urlParams";

/**
 * checks whether given string is a proper url or not
 * @param {string} str string to be checked
 * @returns true if string is a proper URL, false otherwise.
 */
export function checkIsValidURL(str: string) {
    try {
        // URL constructor will fail if string is not of proper string structure
        return !!new URL(str);
    } catch {
        return false;
    }
    return true;
}

export const parseYTUrl = (url: string): string => {
    let parsedYTVideoUrl = url;

    youtube_video_url_formats.some(format => {
        if (url.startsWith(format)) {
            const newUrl = deleteURLSearchParams(url, ["list", "index"]);

            // shows copy link button instead of share and watch later button in youtube videos
            parsedYTVideoUrl = newUrl.replace(
                format,
                "https://www.youtube-nocookie.com/embed/"
            );
            return true;
        }
        return false;
    });

    return parsedYTVideoUrl;
};

/**
 * return content extension from url
 * @param url string containing extension. Eg: dummy.gif
 * @returns File Extension in string. Eg: gif
 */
export const getContentTypeFromUrl = (url: string) => {
    if (checkIsStringEmpty(url)) return "";
    const urlArray = url.split(".");
    if (checkIsArrayEmpty(urlArray)) return "";

    return urlArray[urlArray.length - 1];
};

/**
 * Checks if the given URL is a Google Drive link.
 *
 * @param {string} url - The URL to be checked.
 * @returns {boolean} - Returns true if the URL is a Google Drive link, otherwise false.
 */
export const checkIsGoogleDriveLink = (url: string) => {
    if (checkIsStringEmpty(url)) return false;
    return url.includes("drive");
};
