import { useEffect, useState } from "react";
import { getOptimisedImageUrl } from "repoV2/features/Common/modules/ExlyImage/utils/getOptimisedImageUrl";
import { video_platforms_keys } from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.constants";
import {
    checkIsVimeoVidUrl,
    checkIsYTVidUrl,
    getOembedThumbnailUrl,
    getYTVidThumbnailUrl,
    isWistiaLink,
} from "repoV2/features/Common/modules/URLsNRouting/URLsNRouting.utils";
import { logError } from "repoV2/utils/common/error/error";
import { isString } from "src/features/Common/modules/DataTypes/utils/string";

/**
 * if `light` mode is to be used with thumbnail, we try to optimize by fetching video Thumbnails
 * otherwise takes whatever value of light is set.
 */
export const useOptimizationBasedLightProp = ({
    mediaUrl,
    light: lightArg,
    optimizeUsingThumbnail,
    lightImgFetchWidth,
}: {
    mediaUrl: string;
    light?: boolean | string;
    optimizeUsingThumbnail?: boolean;
    lightImgFetchWidth?: number;
}) => {
    /**
     * DEV - if a string is present in light prop and is not empty, that means user has provided thumbnail
     * we do not need to fetch thumbnail (Currently happens in Testimonials section)
     * otherwise we need to fetch the thumbnail using below logic.
     */
    const isThumbnailAlreadyProvided = (val?: boolean | string) =>
        isString(val) && val;

    const light =
        isThumbnailAlreadyProvided(lightArg) && lightImgFetchWidth
            ? getOptimisedImageUrl({
                  imageUrl: lightArg as string,
                  fetchWidth: lightImgFetchWidth,
              })
            : lightArg;

    const [lightProp, setLightProp] = useState(light);

    const handleFetchThumbnail = async () => {
        if (isThumbnailAlreadyProvided(light)) return;

        try {
            if (light && optimizeUsingThumbnail) {
                let thumbnailUrl = null;
                if (checkIsYTVidUrl(mediaUrl))
                    thumbnailUrl = getYTVidThumbnailUrl(mediaUrl);
                else if (checkIsVimeoVidUrl(mediaUrl))
                    thumbnailUrl = await getOembedThumbnailUrl(
                        mediaUrl,
                        video_platforms_keys.vimeo
                    );
                else if (isWistiaLink(mediaUrl))
                    thumbnailUrl = await getOembedThumbnailUrl(
                        mediaUrl,
                        video_platforms_keys.wisita
                    );

                if (thumbnailUrl) setLightProp(thumbnailUrl);
                else setLightProp(false);
            }
        } catch (error) {
            logError({
                error,
                when: "calling fetchOembed",
                occuredAt:
                    "repoV2/components/common/VideoPlayer/VideoPlayer.utils.ts",
            });
        }
    };

    useEffect(() => {
        handleFetchThumbnail();
    }, [mediaUrl]);

    return { lightProp };
};
